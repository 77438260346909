<template>
  <div>
    <!-- Бүх хуудсанд ашиглагдах header section -->
    <header class="page-header">
      <el-row :gutter="20" align="middle">
        <el-col :span="12">
          <h2>Бүтээгдэхүүний жагсаалт</h2>
        </el-col>
        <el-col :span="12" class="text-right">
          <vue-excel-xlsx
            style="border: none; background-color: #F6F6F7"
            :data="statusChangeData.length > 0 ? statusChangeData : tableExcelData"
            :columns="generateExcelData()"
            :file-name="'Бүтээгдэхүүний тайлан'"
            :file-type="'xlsx'"
            :sheet-name="'Бүтээгдэхүүн'"
            >
            <el-button type="success" icon="el-icon-download" :loading="isExcelLoading" size="small" plain>Татаж авах (.xlsx)</el-button>
          </vue-excel-xlsx>
          <router-link to="/add-product">
            <el-button type="success">Нэмэх</el-button>
          </router-link>
        </el-col>
      </el-row>
    </header>
    <!-- Tabled content -->
    <div class="panel tabled">
      <el-tabs v-model="activeTabName">
        <el-tab-pane :label="'Бүгд (' + totalCount + ')'" name="all">
          <div class="panel-content">
            <div v-if="tableData" class="pl20 pr20 table-filter mb10">
              <el-row :gutter="10" align="middle" type="flex">
                <el-col :span="10">
                  <el-input v-model="search" @input="onChangeFilter" placeholder="Бүтээгдэхүүн хайх" class="input-with-select">
                    <el-button slot="prepend" icon="el-icon-search"></el-button>
                  </el-input>
                </el-col>
                <el-col :span="4">
                  <el-select v-model="statusFilter" @change="onChangeFilter" placeholder="Төлөв сонгох">
                    <el-option
                      v-for="(status, indexStatus) in statusFilterOptions"
                      :key="indexStatus"
                      :label="status.label"
                      :value="status.value">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="4">
                   <el-select value-key="brand_id" v-model="selectedBrands" @change="onChangeFilter" placeholder="Брэнд сонгох" multiple filterable>
                     <el-option
                      v-for="(brand, index) in brandData"
                      :key="index"
                      :label="brand.brand_monName"
                      :value="brand"
                      >
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="4">
                   <el-select value-key="brand_id" v-model="selectedWarehouse" @change="onChangeFilter" clearable placeholder="Агуулах сонгох">
                     <el-option
                      v-for="(warehouse, indexWarehouse) in warehouses"
                      :key="indexWarehouse"
                      :label="warehouse.name_mon"
                      :value="warehouse.warehouse_id"
                      >
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="4" :offset="4" class="text-right">
                  <el-dropdown @command="handleSort" >
                    <el-button type="default">{{sortName}}<i class="el-icon-sort"></i></el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item :command = 'filter.value' v-for="(filter, indexFilter) in filterData" :key="indexFilter">{{filter.label}}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-col>
              </el-row>
            </div>
            <el-row :gutter="10" v-if="statusChangeData.length > 0">
              <el-col :span="24" class="ml20">
                <el-checkbox v-model="isSelect" @change="isAllSelect" border>{{statusChangeData.length + ' Бүтээгдэхүүн'}}</el-checkbox>
                <el-dropdown @command="handleAction">
                  <el-button type="primary" plain style="border-radius: 0px 5px 5px 0px">
                    Үйлдэл<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command = 'changeStatus'>Төлөв өөрчлөх</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-col>
            </el-row>
            <el-table
              v-loading="isLoading"
              :data="tableData"
              :show-header="isShowHeader"
              size="mini"
              style="width: 100%">
              <el-table-column label="" width="50px">
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.checked" @change="checkProduct(scope.row)"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column
                prop="image"
                label="Бүтээгдэхүүн"
                :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <el-row :gutter="10" align="middle" type="flex">
                     <el-col :span="6">
                        <el-image
                          class="image-holder"
                          :src="scope.row.image && Array.isArray(scope.row.image) && scope.row.image.length > 0 ?  scope.row.image[0] :  scope.row.image"
                          fit="contain"></el-image>
                      </el-col>
                    <el-col :span="18"><strong>{{scope.row.name_mon}}</strong></el-col>
                  </el-row>
                </template>
              </el-table-column>
              <el-table-column
                prop="supplier_sku"
                label="Харилцагчийн SKU">
              </el-table-column>
              <el-table-column
                label="Баталсан эсэх">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.is_content_check === true" type="success">Баталсан</el-tag>
                  <el-tag v-else type="info">Батлаагүй</el-tag>
                </template>
              </el-table-column>
              <el-table-column
                prop="categoryName"
                label="Категори"
                :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <span>{{generateCategoryName(scope.row.categoryName)}}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Бүтээгдэхүүний төлөв">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.status === 'active'">Идэвхтэй</el-tag>
                  <el-tag v-else-if="scope.row.status === 'inactive'" type="info">Идэвхгүй</el-tag>
                  <el-tag v-else-if="scope.row.status === 'brand_inactive'" type="info">Брэнд хаалттай</el-tag>
                  <el-tag v-else-if="scope.row.status === 'price_inactive'" type="warning">Үнэ батлуулаагүй</el-tag>
                </template>
              </el-table-column>
              <el-table-column
                label="Төлөв өөрчлөх">
                <template slot-scope="scope">
                  <div>
                    <el-switch
                      v-model="scope.row.changeStatus"
                      @change="updateProductStatus(scope.row)"
                      active-color="#8fc273"
                      inactive-color="#ff929a"
                      active-value="active"
                      inactive-value="inactive">
                    </el-switch>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Эрэмбэ" prop="sort">
              </el-table-column>
              <el-table-column
                prop="brand_monName"
                label="Брэнд">
              </el-table-column>
              <el-table-column
                label="Засах">
                <template slot-scope="scope">
                  <el-button type="primary" @click="cellClick(scope.row)" icon="el-icon-edit" circle></el-button>
                </template>
              </el-table-column>
              <el-table-column
                label="Хуулах">
                <template slot-scope="scope">
                  <el-button type="info" @click="copyData(scope.row)" icon="el-icon-document-copy"></el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              class="text-right mt10"
              background
              :page-size="pageSize"
              :current-page.sync="currentPage"
              :page-sizes="[5, 20, 50, 100]"
              layout="total, sizes, prev, pager, next"
              @size-change="onSizeChange"
              @current-change="onCurrentChange"
              :total="totalCount">
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog
      title="Төлөв өөрчлөх"
      :visible.sync="isChangestatusDialog"
      width="20%">
      <el-select class="block" placeholder="Өөрчлөх төлөвөө сонгоно уу?" v-model="selectStatus">
        <el-option
          v-for="(status, statusIndex) in changeStatusOption"
          :key="statusIndex"
          :label="status.label"
          :value="status.value">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDialog">Болих</el-button>
        <el-button type="success" @click="updateProductsStatus">Хадгалах</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// // @ is an alias to /src
import services from '../../../helpers/services'
import { getUserName, getSupplierId } from '@/utils/auth.js'
import helper from '../../../helpers/helper'

export default {
  name: 'ProductList',
  components: {
    // MenuIcon
  },
  data () {
    return {
      selectedWarehouse: '',
      warehouses: [],
      isExcelLoading: false,
      tableExcelData: null,
      productData: null,
      isSelect: false,
      isShowHeader: true,
      selectStatus: '',
      isChangestatusDialog: false,
      statusChangeData: [],
      field: '',
      sort: '',
      sortName: 'Эрэмбэлэх',
      tableCategoriesData: [],
      tableCategories: [],
      totalCount: 0,
      pageSize: this.$route.query.size ? JSON.parse(this.$route.query.size) : 20,
      pageFrom: 0,
      currentPage: this.$route.query.page ? JSON.parse(this.$route.query.page) : 1,
      tableData: [],
      brandData: null,
      statusFilter: '',
      filter: [],
      selectedBrands: [],
      brandList: [],
      backupData: null,
      isLoading: false,
      search: '',
      activeTabName: 'all',
      changeStatusOption: [{
        value: 'active',
        label: 'Идэвхитэй'
      }, {
        value: 'inactive',
        label: 'Идэвхигүй'
      }],
      statusFilterOptions: [{
        value: '',
        label: 'Бүгд'
      }, {
        value: 'active',
        label: 'Идэвхитэй'
      }, {
        value: 'inactive',
        label: 'Идэвхигүй'
      }, {
        value: 'price_inactive',
        label: 'Үнэ батлуулах'
      }, {
        value: 'brand_inactive',
        label: 'Брэнд хаалттай'
      }],
      filterData: [
        {
          label: 'Бүгд',
          value: 'all'
        }, {
          label: 'Нэр А - Я',
          value: 'firstChar'
        }, {
          label: 'Нэр Я - А',
          value: 'lastChar'
        }, {
          label: 'Шинэ эхэнд',
          value: 'newest'
        }, {
          label: 'Хуучин эхэнд',
          value: 'oldest'
        }, {
          label: 'Эрэмбэ өсөхөөр',
          value: 'sortAsc'
        }, {
          label: 'Эрэмбэ буурхаар',
          value: 'sortDesc'
        }, {
          label: 'Агуулахад байхгүй ч зарах',
          value: 'sellCheck'
        }
      ]
    }
  },
  created () {
    var from = (this.pageSize * (this.currentPage - 1))
    var size = this.pageSize
    if (this.$route.query.page && this.$route.query.size) {
      const incomingWarehouse = this.$route.query.warehouse
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      const incomingSearch = this.$route.query.search
      const incomingStatus = this.$route.query.status
      const incomingField = this.$route.query.field
      const incomingSort = this.$route.query.sort
      const incomingsortName = this.$route.query.sortName
      const incomingBrand = JSON.parse(this.$route.query.brand).length > 0 ? JSON.parse(this.$route.query.brand) : []
      this.selectedWarehouse = incomingWarehouse
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      this.search = incomingSearch
      this.statusFilter = incomingStatus
      this.field = incomingField
      this.sort = incomingSort
      this.sortName = incomingsortName
      this.selectedBrands = incomingBrand
      from = (this.pageSize * (incomingPage - 1))
      size = incomingSize
    }
    this.selectedWarehouse = this.$route.query.warehouse
    this.pageFrom = from
    this.pageSize = size
    this.getProductsBySupplierId(size, from)
    this.getProductsExecBySupplierId(size, from)
    this.getWarehousesBySupplierId()
  },

  methods: {
    async getWarehousesBySupplierId () {
      this.loading = true
      const body = {
        supplier_id: await getSupplierId(),
        size: 100,
        from: 0
      }
      services.getWarehousesBySupplierId(body).then(response => {
        this.loading = false
        if (response.status === 'success' && response.data) {
          this.warehouses = response.data
        } else {
          this.warehouses = []
        }
      })
    },
    generateExcelData  () {
      const tempData = [
        {
          label: 'Бүтээгдэхүүн',
          field: 'name_mon'
        },
        {
          label: 'Харилцагчийн SKU',
          field: 'supplier_sku'
        },
        {
          label: 'Борлуулах үнэ',
          field: 'price',
          dataFormat: this.priceFormat
        },
        {
          label: 'Татах үнэ',
          field: 'get_price'
        },
        {
          label: 'Төлөв',
          field: 'status'
        }
      ]
      return tempData
    },
    isAllSelect (data) {
      this.tableData.forEach(product => {
        if (data === true) {
          product.checked = true
          this.statusChangeData = this.statusChangeData.filter(element => element.productId !== product.productId)
          this.statusChangeData.push(product)
        } else {
          product.checked = false
          this.statusChangeData = this.statusChangeData.filter(element => element.productId !== product.productId)
        }
      })
    },
    checkProduct (product) {
      if (product.checked === true) {
        this.isShowHeader = false
        this.statusChangeData.push(product)
      } else {
        this.statusChangeData = this.statusChangeData.filter(element => element.productId !== product.productId)
        if (this.statusChangeData.length === 0) {
          this.isShowHeader = true
        }
      }
    },
    cancelDialog () {
      this.statusChangeData = []
      this.isChangestatusDialog = false
      this.isShowHeader = true
      this.selectStatus = ''
      this.getProductsBySupplierId(this.pageSize, (this.pageSize * (this.currentPage - 1)))
    },
    updateProductsStatus () {
      this.isLoading = true
      const tempProductId = []
      this.statusChangeData.forEach(product => {
        if (product.sell_check === true) {
          tempProductId.push(product.productId)
        }
      })
      const body = {
        product_id: tempProductId,
        status: this.selectStatus
      }
      services.updateProductsStatus(body).then(response => {
        if (response.status === 'success') {
          this.isShowHeader = true
          this.alertReporter('Амжилттай', response.message, 'success')
          this.isLoading = false
          this.isChangestatusDialog = false
          this.statusChangeData = []
          this.selectStatus = ''
          this.getProductsBySupplierId(this.pageSize, (this.pageSize * (this.currentPage - 1)))
        } else {
          this.alertReporter('Алдаа гарлаа', response.message, 'error')
          this.isShowHeader = false
          this.isChangestatusDialog = true
          this.selectStatus = ''
          this.isLoading = false
        }
      })
      this.isChangestatusDialog = false
    },
    handleAction (data) {
      if (data === 'changeStatus') {
        this.isChangestatusDialog = true
      }
    },
    generateCategoryName (categoryName) {
      let tempCategoryName = ''
      categoryName.forEach((element, index) => {
        tempCategoryName = tempCategoryName + (index === 0 ? '' : ', ') + element
      })
      return tempCategoryName
    },

    getProductsBySupplierId (size, from) {
      this.isLoading = true
      const tempBrandId = []
      if (this.selectedBrands.length > 0) {
        this.selectedBrands.forEach(element => {
          tempBrandId.push(element.brand_id)
        })
      }
      const body = {
        search_text: this.search,
        field: this.field,
        sort: this.sort,
        status: this.statusFilter,
        warehouse_id: this.selectedWarehouse,
        size: size,
        from: from,
        brand_id: tempBrandId
      }
      services.getProductsBySupplierId(body).then(response => {
        if (response.status === 'success' && response.data) {
          this.isLoading = false
          const tempData = JSON.parse(JSON.stringify(response.data))
          this.brandData = response.brands
          this.totalCount = response.total
          const checkedProductIds = this.statusChangeData.map(pro => pro.productId)
          tempData.forEach(el => {
            if (checkedProductIds.indexOf(el.productId) === -1) {
              el.checked = false
            } else {
              el.checked = true
            }
            if (el.status === 'active') {
              el.changeStatus = el.status
            }
          })
          this.tableData = tempData
        } else {
          this.isLoading = false
        }
      })
    },

    getProductsExecBySupplierId (size, from) {
      this.isExcelLoading = true
      const tempBrandId = []
      const tempProductId = []
      if (this.selectedBrands.length > 0) {
        this.selectedBrands.forEach(element => {
          tempBrandId.push(element.brand_id)
        })
      }
      const body = {
        search_text: this.search,
        field: this.field,
        sort: this.sort,
        status: this.statusFilter,
        warehouse_id: this.selectedWarehouse,
        size: size,
        from: from,
        brand_id: tempBrandId,
        product_id: tempProductId
      }
      services.getProductsExecBySupplierId(body).then(response => {
        if (response.status === 'success' && response.data) {
          this.tableExcelData = response.data
          this.isExcelLoading = false
        } else {
          this.isExcelLoading = false
        }
      })
    },

    generateCurrency (val) {
      return helper.generateCurrency(parseFloat(val))
    },

    async updateProductStatus (data) {
      const body = {
        status: data.changeStatus,
        product_id: data.productId,
        user_mail: await getUserName()
      }
      this.isLoading = true
      services.updateProductStatus(body).then((response) => {
        if (response.status === 'success') {
          this.alertReporter('Амжилттай', response.message, 'success')
          const isFound = this.tableData.findIndex(el => el.productId === data.productId)
          if (isFound > -1) this.tableData[isFound].status = data.changeStatus
          this.isLoading = false
        } else {
          this.alertReporter('Алдаа', response.message, 'error')
          data.changeStatus = data.status
          this.isLoading = false
        }
      })
    },
    handleSort (data) {
      const filters = {
        sortAsc: {
          field: 'sort',
          sort: 'asc',
          sortName: 'Эрэмбэ өсөхөөр'
        },
        sortDesc: {
          field: 'sort',
          sort: 'desc',
          sortName: 'Эрэмбэ буурхаар'
        },
        newest: {
          field: 'created_at',
          sort: 'desc',
          sortName: 'Шинэ эхэнд'
        },
        oldest: {
          field: 'created_at',
          sort: 'asc',
          sortName: 'Хуучин эхэнд'
        },
        firstChar: {
          field: 'name_mon',
          sort: 'asc',
          sortName: 'Нэр А - Я'
        },
        lastChar: {
          field: 'name_mon',
          sort: 'desc',
          sortName: 'Нэр Я - А'
        },
        all: {
          field: '',
          sort: '',
          sortName: 'Эрэмбэлэх'
        },
        sellCheck: {
          field: 'sell_check',
          sort: true,
          sortName: 'Агуулахад байхгүй ч зарах'
        }
      }
      const currentFilter = JSON.parse(JSON.stringify(filters[data]))
      this.field = currentFilter.field
      this.sort = currentFilter.sort
      this.sortName = currentFilter.sortName
      this.$router.push({ name: 'products', query: { page: this.currentPage, size: this.pageSize, search: this.search, status: this.statusFilter, field: this.field, sort: this.sort, sortName: this.sortName, brand: JSON.stringify(this.selectedBrands), warehouse: this.selectedWarehouse } }).catch(() => {})
      this.getProductsBySupplierId(this.pageSize, (this.pageSize * (this.currentPage - 1)))
      this.getProductsExecBySupplierId(this.pageSize, (this.pageSize * (this.currentPage - 1)))
    },

    cellClick (item) {
      this.$router.push({ name: 'updateProduct', params: { product_id: item.productId } }).catch(() => {})
    },

    copyData (item) {
      this.$router.push({ name: 'addProduct', params: { product: JSON.stringify(item) } }).catch(() => {})
    },

    async onChangeFilter () {
      this.currentPage = 1
      this.$router.push({ name: 'products', query: { page: this.currentPage, size: this.pageSize, search: this.search, status: this.statusFilter, field: this.field, sort: this.sort, sortName: this.sortName, brand: JSON.stringify(this.selectedBrands), warehouse: this.selectedWarehouse } }).catch(() => {})
      this.getProductsBySupplierId(this.pageSize, (this.pageSize * (this.currentPage - 1)))
      this.getProductsExecBySupplierId(this.pageSize, (this.pageSize * (this.currentPage - 1)))
    },

    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'top-right'
      })
    },

    onCurrentChange (current) {
      this.isSelect = false
      this.currentPage = current
      this.$router.push({ name: 'products', query: { page: this.currentPage, size: this.pageSize, search: this.search, status: this.statusFilter, field: this.field, sort: this.sort, sortName: this.sortName, brand: JSON.stringify(this.selectedBrands), warehouse: this.selectedWarehouse } }).catch(() => {})
      this.getProductsBySupplierId(this.pageSize, (this.pageSize * (this.currentPage - 1)))
    },

    onSizeChange (size) {
      this.pageSize = size
      this.$router.push({ name: 'products', query: { page: this.currentPage, size: this.pageSize, search: this.search, status: this.statusFilter, field: this.field, sort: this.sort, sortName: this.sortName, brand: JSON.stringify(this.selectedBrands), warehouse: this.selectedWarehouse } }).catch(() => {})
      this.getProductsBySupplierId(this.pageSize, (this.pageSize * (this.currentPage - 1)))
    }
  }
}
</script>

<style>
  .el-table .warning-row {
    background: oldlace;
  }

  .el-checkbox.is-bordered {
    padding: 10px 20px 9px 10px;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
</style>
